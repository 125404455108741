body, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat'!important;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
.profile-app {
  display: flex;
  width: 100%;
  min-height: 100%;
  font-size: 14px;
  padding: 140px 20px 0px 20px;
}
.profile-form {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 35px;
  margin: 10px 30px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 170px);
}
.Icon-inside {
  position:relative;
 }

 .Icon-inside i {
  position:absolute;
  right:18px;
  top:25px;
  padding:10px 10px;
  color:#494949;
 }
.profile-input {
  width: 100%;
  height: 64px;
  padding: 0px 10px;
  background: #EFF0F6;
  border-radius: 15px;
  border: 2px solid transparent;
  margin: 10px 0px;
  outline: none;
}
.profile-avatar {
  width:178px;
  height:178px;
}
.profile-name {
  font-size: 18px;
}
.profile-textfield {  
  background-color: #EFF0F6;
}