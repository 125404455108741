.lab-result-description-section {
    width: 100%;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; */
}

.lab-result-category-container {
    padding: 0% 10%;
    padding-left: 0px;
    margin-top: 30px;
}

.lab-result-category-button {
    vertical-align: middle;
    background: #f7f7fa;
    width: 100%;
    border-radius: 30px;
    box-shadow: -1px 1px 5px #ddd;
    position: relative;
    display: inline-flex;
    cursor: pointer;
    padding: 2% 5%;
    margin: 10px 0;
}

.lab-result-category-button:hover {
    color: #4939E3;
}

.lab-result-category-button h4 {
    padding-top: 5px;
}

.lab-result-description-card {
    padding: 30px 50px;
    background-color: #F7F7FA;
    width: 100%;
    border-radius: 37px;
    /* margin-top: 30px; */
}


.round_arrow-add-task {
    transform: scale(1.5) !important;
    float: right !important;
    right: 20px !important;
    position: absolute !important;
    /* top: 15px !important; */
}