
html,
body,
body > div { /* the react root */
  margin: 0;
  padding: 0;
  height: 100%;
}

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1em 0!important;
  padding: 0;
}

b { /* used for event dates/times */
  margin-right: 3px;
}

.demo-app {
  display: flex;
  width: 100%;
  min-height: 100%;
  font-family: 'Montserrat'!important;
  font-size: 14px;
  padding: 7.5% 3% 0 0;
}

@media only screen and (max-width: 1335px){
  .demo-app{
    padding: 2% 3% 0 0;
  }
}

/* ------------------------------------------custom-------------------------------------*/
.fc .fc-daygrid-day-frame {
  /* background-color: rgb(211, 211, 211); */
  
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #d8d6d6fd!important;
  color:white!important;
  /* background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15)); */
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  color: rgb(255, 8, 234)!important;
  border-radius: 100%!important;
  padding: 4px 17px!important;
  border: 2px solid rgb(255, 8, 234);
  /* background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15));*/
}
.fc-daygrid-day-number{  
  padding: 12px 12px 0 0;
  font-size: 18px;
  font-weight: bold;
}
.fc-daygrid-day-top .fc-daygrid-day-number {
  position: relative;
  text-align: start;
  margin-left: 0px;
}
.fc .fc-daygrid-day-frame {
  background-color: #f3f3f3;
}
.fc .fc-day-other .fc-daygrid-day-frame {
  background-color: rgb(255, 255, 255);
}
.fc-toolbar-chunk {
  position: relative;
  top: 47px;
  right: 38px;
  z-index: 12;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0em!important;
}
.fc-daygrid-day-number{
  color:rgb(39, 39, 39);
}
thead tr {
  border: 2px solid rgb(173, 173, 173);
}
.fc-col-header-cell-cushion  {
  color: #777777;
}
.fc-theme-standard td, .fc-theme-standard th {
  border: 1px solid #fff!important;
}

.fc .fc-button-primary {
  color: rgb(255, 255, 255)!important;
  font-weight: bold!important;
  background-color: #e7e9eb!important;
  border-color: #ffffff00!important;
  padding:6px 32px!important;
}
.btn.btn-primary:focus {
  outline: none !important;
  box-shadow: none;
  background-color: white;
  border-style: outset;
}
.btn.btn-primary:hover {
  outline: none !important;
  box-shadow: none;
  background-color: white;
  border-style: outset;
}
.fc .fc-toolbar-title {
  position: relative;
  left: 0px;
  top: 0px;
  width: 300px;
  text-align: start;
  font-weight: bold;
  font-family: 'Montserrat' !important;
}

.fc-toolbar.fc-header-toolbar {
  background-color: #ffffff;
}

.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: relative;
  width: 100%;
  height: 40px;
  left: 20px;
  top: 5%;
}
.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child div {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  position: relative;
  width: 70%;
  height: 40px;
  left: 0px;
  top: 0px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 3% 0px;
}
.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child div .fc-toolbar-title {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 0px;

  position: relative;
  width: 40%;
  height: 40px;
  left: 0px;
  top: 0px;
  
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
  
}

.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child div .fc-prev-button {
  position: relative;
  width: 5%;
  height: 10%;
  top: -25%;
  background-color: #ffffff;  
  transform: rotate(90deg);
  left: auto;
  border: none;
  outline: none;
  border-width: 0vh;
}
.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child div .fc-next-button {
  position: relative;
  width: 5%;
  height: 10%;
  top: 20%;
  background-color: #ffffff;  
  transform: rotate(90deg);
  left: -5%;
  border: none;
  outline: none;
}
.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child div .fc-button-primary {
  color: rgb(131, 131, 131)!important;
  
  font-weight: bold!important;
  background-color: #EFF0F600!important;
  border-color: #EFF0F600!important;
  padding: 0px 0px!important;
  border: 0;
  outline: 0;
}
.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child div .fc-button-primary:active {
  outline: none !important;
  box-shadow: none;
  background-color: white;
  border-style: outset;
}
.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child div .fc-button-primary:focus {
  outline: none !important;
  box-shadow: none;
  background-color: white;
  border-style: outset;
}
.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:last-child{
  top: 30px;
}
.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button-group {
  padding: 5px;
  background-color: #EFF0F6;
  border-radius: 10px;
}
.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button-group .fc-button-primary {
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 1px;
  font-size: 2vh;

}
.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button-group .fc-button-primary:focus {
  outline: none !important;
  box-shadow: none;
  background-color: white;
  border-style: outset;
}
.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button-group .fc-button-primary:active {
  outline: none !important;
  box-shadow: none;
  background-color: white;
  border-style: outset;
}
.span-prevdate {
  margin: 0pt;
  padding: 0pt;
  width: 10%;
  height: 10%;
}
.span-nextdate {
  margin: 0pt;
  padding: 0pt;
  width: 10%;
  height: 10%;
}
.span-prevdate svg {
  color: rgb(131, 131, 131)!important;
  transform: rotate(90deg);
}
.span-nextdate svg {
  color: rgb(131, 131, 131)!important;
  transform: rotate(90deg);
}
.fc-event .fc-event-main {
  position: relative;
  z-index: 2;
  border-left: 2px solid rgb(20, 149, 255);
}
.fc-daygrid-event-harness {
  border-left:3px solid rgb(153, 111, 209);
  padding: 0px!important;
}
.timeline-section {
  height: calc(100vh - 300px);
  overflow: auto;
  padding-right: 0!important;
  /* border-top: 2px solid rgb(228, 228, 228); */
}
.date-header {
  font-size: 25px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  /* border-bottom: 2px solid rgb(218, 218, 218); */
}

.fc-weekButton-button {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0)!important;
}
.fc .fc-button-primary {
  color: rgb(0, 0, 0)!important;
  font-weight: bold!important;
  background-color: #EFF0F6!important;
  border-color: #9c9fa100!important;
  padding: 6px 64px!important;
}
.fc-button.fc-button-active {
  background-color: rgb(255, 255, 255)!important;
  color:rgb(0, 0, 0)!important;
}
.timeline {
  position: relative;
  z-index: 39;
  right: 0;
}
.timeline-line {
  border-bottom:2px dashed #BFBEFC;
}
.timeline-circle {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 5px solid #BFBEFC;
  position: relative;
  float: right;
  bottom: 15px;
  right: 0px;
  background-color: #610BEF;
}
.fc .fc-daygrid-event {
  padding: 0px;
}
.fc-v-event {
  display: block;
  border: 1px solid #c9e4ff!important;
  background-color: #dcefff!important;
}
.fc .fc-daygrid-day-number {
  top: 0px!important;
  position: relative;
  margin-right: auto;
}
.fc-col-header-cell {
  text-align: left!important;
}
.fc .fc-toolbar-title {
  font-size: 34px!important;
  margin: 0;
}
/* ------------------------------------------------------------------------------------*/





@media only screen and (max-width: 100px){
  .demo-app{
    padding-left: 2% !important;
  }
}

.demo-app-sidebar {
  width: 23%;
  min-width: 350px;
  line-height: 1.5;
}

.demo-app-sidebar-section {
  padding-top: 27px;
  padding-left: 20px;
}

.demo-app-main {
  flex-grow: 1;
  padding: 0 0 0 3em;
  margin: 2em 0em;
  min-width: 1100px;
  height: calc(100vh - 145px);
}

.fc { /* the calendar root */
  margin: 0 auto;
}

.fc-toolbar{
  display: flex !important;
}
/* .fc-toolbar-title{
  display: none;
} */
.fc-today-button{
  display: none !important;
}
/* .fc-button-group{
  display: none !important;
} */

.react-calendar{
  margin: 15px auto 0px;
  border: none !important;
  border-radius: 35px;
  width: auto !important;
  background: none !important;
}

.non-show-picker-calendar{
  margin-top: 0px !important;
}

.fc-event-main {
  display: grid;
}

.intro-page-container{
  display: grid !important;
}
#calendar {
  color: #610BEF;
  font-weight: bold;
  font-family: 'Montserrat' !important;
}
.calendar-main-section{
  padding-left: 0;
  height: auto !important;
}
.react-calendar__tile--active{
  background: transparent !important;
}
.react-calendar__tile--active abbr{
  color: black;
}
.fc table{
  line-height: 40px;
}
.fc-view{
  background: white !important;
  padding: 50px 40px 10px;
  border-radius: 0px;                /* ---------------------------custom------------------------------*/
}
.fc-scrollgrid-section-header th{
  border-top: none;
  border-left: none;
  border-right: none;
}
.fc-scrollgrid-section-header .fc-scroller{
  overflow: hidden !important;
}
.modal-content{
  border-radius: 30px !important;
  width: 80% !important;
}
.modal-timeslot .modal-content{
  width:100% !important;
  border-radius: 0px !important;
}
.modal-timeslot-item{
  width: 22%;
  float:left;
  height: 35px;
  background-color: #d1d1d0;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 10px;
}
.modal-body .form-group{
  margin: 10px 20px;
}
.react-datepicker__input-container input{
  width: 100%;
  color:rgb(0, 0, 0)!important;
  background-color: transparent;
  text-emphasis-color:  #000000;
}
.react-datepicker-wrapper{
  width: 100%;
}
.modal-header .close:focus {
outline: none !important;
}
/* .side-bar{
  width : 8%;                         --------------------------------custom---------------------------------
} */
.side-bar .side-bar-full-title {
  font-size: 50px;
}
@media only screen and (max-width: 1335px){
  .side-bar{
    width : 100vw;
  }
}
.search-bar{
  margin: 5px;
  width: 100%;
  overflow: initial;
  display: block;
}
.fade.modal.show{
  opacity: 1 !important;
}

.calendar_default_event_inner {
  background: #2e78d6 !important;
  color: #fff;
  border: none;
  font-size: 10pt;
  padding: 5px;
  opacity: 0.8;
}

.calendar-time-events {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.calendar-week-picker-section {
  height: 100%;
  width: 300px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  z-index: 5
}

.navigator_default_main {
  margin-top: 25px;
  width: 100% !important;
  border: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F7F7FA !important;
}

.navigator_default_month {
  background-color: #F7F7FA;
  width: 250px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .navigator_default_titleleft {
  display: none;
}

.navigator_default_titleright {
  display: none;
} */

.navigator_default_title {
  border: none;
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #F7F7FA !important;
  font-size: 20px;
  font-family: mediumText;
  color: #333333;
  position: initial !important;
  padding-top: 10px;
  padding-bottom: 230px;
  border-top: none !important;
  border-bottom: none !important;
}

.calendar-week-schedule-view {
  margin-top: 30px !important;
  flex: 1;
  background-color: white;
  border-radius: 40px 40px 40px 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar_default_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none !important;
  width: 90%;
}

.calendar_default_scroll {
  width: 100%;
  flex: 1;
  border-top: 1px solid #4939E3;
  margin-top: 15px;
}

.calendar_default_colheader_inner {
  font-size: 16px !important;
  font-family: 'Montserrat' !important;
  color: #4939E3 !important;
  background-color: white !important;
  border: none !important;
}

.calendar_default_cornerright_inner {
  background-color: white;
  border: none;
}

.calendar_default_corner {
  display: none;
}

.calendar_default_rowheader_inner {
  border: none !important;
  background-color: white !important;
  color: #4939E3 !important;
}

.show-calendar-picker {
  width: 50px;
  height: 20px;
  background: linear-gradient(to right, #877CEC, #4939E3);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  margin: 0px;
  display: none;
}

.calendar-sidbar {
  height: calc(100vh - 145px);
  overflow: auto;

}

.show-picker-calendar {
  display: flex;
  margin-bottom: auto;
  z-index: 2;
  flex-direction: column;
  width: 100%;
}

.non-show-picker-calendar {
  display: flex;
  margin-bottom: auto;
  z-index: 2;
  flex-direction: column;
  width: 100%;
}

@media only screen and (max-width: 100%) {
  .calendar-week-picker-section {
      width: 100% !important;
      background-color: #F7F7FA;
      z-index: 5;
      position: fixed;
      height: auto;
  }

  .show-picker-calendar {
      display: flex;
      margin-bottom: auto;
      z-index: 2;
      flex-direction: column;
      width: 100vw !important;
      height: 500px;
      overflow-y: auto;
      align-items: center;
  }

  .avatars-title-text {
    color: #333333;
  }
  .avatars-title-add-button {
    color: #610BEF;
    
  }
  .avatars-title-container {
      width: 100%;
  }

  .non-show-picker-calendar {
      display: flex;
      margin-bottom: auto;
      z-index: 2;
      display: none;
      flex-direction: column;
      width: 100%;
  }

  .show-calendar-picker {
      display: flex;
  }

  .navigator_default_main {
      background-color: #F7F7FA;
  }

  .calendar-week-schedule-view {
      z-index: 1;
      margin-left: 0px !important;
      width: 100%;
      margin-top: 100px;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 40px;
      overflow-x: auto;
  }

  .calendar_default_main {
      min-width: 800px;
  }
}

/** new style */

.non-show-picker-calendar {
  margin-top: 0 !important;
}

.intro-section-part {
  margin-left: 25%;
}
.intro-page-intro-section {
  flex: 1 1;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50px 0 0 50px;
  box-shadow: -1px 3px 6px rgba(0,0,0,.1);
  margin-top: 10px;
  margin-left: 20px;
}

.react-calendar__viewContainer {
  box-sizing: border-box;
  color: black;
  padding: 2px;
  background: white;
  border-radius: 20px;
  padding: 16px;
}
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__prev-button {
  display: none;
}
button.react-calendar__navigation__label {
  background-color: #fff0;
  font-size: 21px;
  text-align: left;
  font-weight: 200;
  padding: 0;
}
button.react-calendar__navigation__arrow{
  display: none;
}
.react-calendar__navigation__next-button {
  display: none;
}
.react-calendar__navigation {
  height: 25px;
  margin-bottom: 0px !important;
}
.react-calendar__month-view__days__day--weekend {
  color: #877CEC !important;
}
.fc-theme-standard td, .fc-theme-standard th{
  border: 2px solid #fff;
}

.fc-timegrid-slot-label {
  color: #4939E3;
  font-weight: bold;
  border: 0 !important;
}
.fc-timegrid-slot-lane{
  height: 1.5em;
  background-color: #F7F7FA;
  border: 2px solid white;

}

.fc-scrollgrid{
  border: 0 !important;
}
.react-calendar__navigation__next2-button {
  display: none;
  }


button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  display: none;
}

.react-calendar__month-view__days__day--weekend {
   color: #877CEC;
}
.custom-modal-avaliable-timeslot-container{
  position:absolute;
  width:100%;
  height:100%;
  background:#000000ad;
  z-index: 999;
}
.custom-modal-avaliable-timeslot-body{
  width: 350px;
  /* height: 450px; */
  position: absolute;
  top: 7px;
  right: 0px;
  z-index: 9999 !important;
  background: white;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}
.custom-modal-avaliable-timeslot-item-body{
  width: 90%;
  margin-left: 5%;
  height: 95%;
  margin-top: 5%;
  margin-bottom: 25px;;
}
.custom-modal-avaliable-timeslot-title{
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
}
.custom-modal-avaliable-timeslot-title-body{
  font-size: 20px;
  font-weight: 600;
  font-family: 'Montserrat' !important;
  padding-top: 15px;
}
.custom-modal-avaliable-timeslot-main-body{
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 25px;
}
.custom-modal-avaliable-timeslot-item-one{
  width: 50%;
}
.custom-modal-avaliable-timeslot-item-second{
  width: 48%;
  margin-left: 2%;
}
#avaliabletimeslotdate{
  font-family: 'Montserrat' !important;
    font-weight: 200 !important;
    color: #3e3e3e;
    border: none;
    background: #eaebeb;
}