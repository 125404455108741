.super-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F7F7FA;
}

.page-main-container {
  width: 100%;
  height: 100%;
}
@font-face {
  font-family: boldTitles;
  src: url('./assets/fonts/Montserrat-Bold.otf');
}

@font-face {
  font-family: regularText;
  src: url('./assets/fonts/Montserrat-Regular.otf');
}

@font-face {
  font-family: mediumText;
  src: url('./assets/fonts/Montserrat-Medium.otf');
}

* {
  box-sizing: border-box;
}

.notification__item {
  border-left: 8px solid darkblue;
  background-color: #134fa9;
}

.notification__title {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.notification__message {
  color: #fff;
  max-width: calc(100% - 15px);
  font-size: 14px;
  line-height: 150%;
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}

.notification__close {
  background-color: transparent !important;
  right: 5px;
  top: 5px;
}