.avatars {
    width: 23%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    position: fixed;
    padding: 0px 20px;
}

.avatars-loader-spinner-container {
    width: 20%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    justify-content: center;
    align-items: center;
}

.avatar-main-section {
    width: 100%;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
}

.avatar-main-section-showed {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.avatars-title-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.avatars-title-text {
    font-family: boldTitles;
}

.avatars-title-add-button {
    width: 29px;
    height: 29px;
    cursor: pointer;
}

.search-bar {
    width: 95%;
    height: 49px;
    background-color: white;
    box-shadow: 0px 2px 4px #0000001A;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.search-icon {
    margin-left: 15px;
    margin-right: 15px;
}

.search-input {
    height: 100%;
    flex: 1;
    width: 50%;
    border: none;
    font-size: 15px;
    outline: none;
    font-family: regularText;
}

.users-avatar {
    cursor: pointer;
    height: calc(100vh - 135px) !important;
    overflow-y: hidden;
    overflow-x: hidden;
    transition-duration: 0.4s;
}

.users-avatar:hover {
    overflow-y: scroll;
}

#avatar-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 50px;
	background-color: #F5F5F5;
}

#avatar-scrollbar::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}

#avatar-scrollbar::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 50px;
}

.user-avatar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    padding: 15px 0px;
    padding-right: 15px;
    align-items: center;
}

.user-avatar-selected {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    padding: 10px 10px;
    background: linear-gradient(to right, #877CEC, #4939E3);
    border-radius: 55px;
    box-shadow: 0px 3px 6px #0000001A;
    align-items: center;
}

.avatar-image {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    object-fit: cover;
}

.user-info {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    padding-left: 15px;
}

.user-avatar .user-name-text {
    margin: 0px;
    text-align: left;
    width: 100%;
    font-size: 17px;
    color: #333333;
    font-family: mediumText;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 120px;
    max-width: 200px;
    text-align: left;
}

.user-avatar .user-type-text {
    margin: 0px;
    text-align: left;
    width: 100%;
    font-size: 14px;
    color: #333333;
    font-family: regularText;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100px;
    text-align: left;
}

.user-avatar-selected .user-name-text {
    margin: 0px;
    text-align: left;
    width: 100%;
    font-size: 17px;
    color: white;
    font-family: mediumText;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 120px;
    text-align: left;
    max-width: 160px;
}

.user-avatar-selected .user-type-text {
    margin: 0px;
    text-align: left;
    width: 100%;
    font-size: 14px;
    color: white;
    font-family: regularText;
}

.show-avatars {
    width: 50px;
    height: 20px;
    background: linear-gradient(to right, #877CEC, #4939E3);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    cursor: pointer;
    margin: 0px;
    display: none;
}

@media only screen and (max-width: 1460px) {
    .user-avatar .user-name-text {
        font-size: 20px;
    }

    .user-avatar .user-type-text {
        font-size: 17px;
    }

    .user-avatar-selected .user-name-text {
        font-size: 20px;
    }

    .user-avatar-selected .user-type-text {
        font-size: 17px;
    }

    .avatar-image {
        width: 55px;
        height: 55px;
    }
}

@media only screen and (max-width: 1460px) {
    .user-avatar-selected .user-name-text {
        font-size: 17px;
    }

    .user-avatar .user-name-text {
        font-size: 17px;
    }

    .user-avatar .user-type-text {
        font-size: 14px;
    }

    .user-avatar-selected .user-type-text {
        font-size: 14px;
    }

    .avatar-image {
        width: 50px;
        height: 50px;
    }
}

@media only screen and (max-width: 1330px) {
    .avatars {
        width: 27%;
    }
}

@media only screen and (max-width: 1200px) {
    .avatar-image {
        width: 45px;
        height: 45px;
    }
}
@media only screen and (max-width: 890px) {
    .avatars {
        width: 100% !important;
        z-index: 1;
        height: auto;
    }

    .avatar-main-section {
        padding-top: 5px;
        display: none;
    }

    .show-avatars {
        display: flex;
        position: absolute;
        z-index: 2;
    }

    .avatars-title-container {
        justify-content: space-evenly;
    }

    .users-avatar {
        width: 100%;
        height: calc(100vh - 180px);
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: none;
        overflow-y: auto;
        padding-bottom: 20px;
    }

    .user-avatar-selected {
        width: 300px;
    }

    .user-avatar {
        width: 300px;
    }

    .avatar-main-section-showed {
        width: 100%;
        background-color: white;
    }

    .scroll-card-section {
        margin-top: 245px;
    }

    .users-avatar::-webkit-scrollbar {
        width: 10px; 
      }

      .users-avatar::-webkit-scrollbar-track {
      background: #999999;  
    }

    .users-avatar::-webkit-scrollbar-thumb {
      background-color: #333333; 
      border-radius: 20px; 
      border: 3px solid #999999; 
    }
}

/* #avatar-item {
    opacity: 1;
    visibility: hidden;    
  } */
  
  .is-visible {
    opacity: 1 !important;
    visibility: visible;
    transition: opacity 300ms ease-out, transform 300ms ease-out;
    will-change: opacity, visibility;
  }
  .is-invisible {
    opacity: 0 !important;
    transition: opacity 300ms ease-out, transform 300ms ease-out;
    will-change: opacity, visibility;
  }