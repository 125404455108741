.detail-main-section {
    padding-left: 15%;
    display: flex;
    flex-direction: row;
    flex: 1;
}
.detail-row-grid-1 {
    width: 60%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.detail-row-grid-2 {
    width: 40%;
}
.video-call-window {
    width: 100%;
    height: auto;
    background-image: url("../../assets/images/call-background.jpg");
    background-size: cover;
    background-position: center;
    height: 460px;
    background-repeat: no-repeat;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
}
.video-call-main-section {
    width: 60%;

}
.details-container {
    background-color: #f7f7fa;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.detail-every-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}
.detail-every-section-personal-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}
.detail-title {
    font-size: 30px;
    font-family: mediumText;
    color: #333333;
    margin: 0px;
    margin-top: 20px;
}
.assigned-tasks-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.add-tasks-button {
    width: 33%;
    height: 90px;
    box-shadow: 0px 2px 4px #0000001A;
    border: 2px dashed #4939E3;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-tasks-button-text {
    font-size: 20px;
    font-family: mediumText;
    color: #4939E3;
}
.assigned-tasks-cards {
    width: 33%;
    height: 90px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    flex-direction: column;
    margin: 10px;
}
.assigned-tasks-texts-title {
    font-size: 15px;
    font-family: mediumText;
    color: #333333;
    margin-top: 10px;
    text-align: left;
    width: 100%;
    padding-left: 20px;
    margin-bottom: 0px;
}
.assigned-tasks-texts-description {
    font-size: 15px;
    font-family: mediumText;
    color: #877CEC;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
    padding-left: 20px;
    margin-top: 0px;
}
.detail-lab-work-description-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    background-color: white;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0px 2px 4px #0000001A;
    border-radius: 40px;
}
.detail-grid-2-every-section {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}
.notes-container-card {
    width: 100%;
    box-shadow: 0px 2px 4px #0000001A;
    border-radius: 40px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
}
.notes-title {
    font-size: 17px;
    font-family: mediumText;
    color: #4939E3;
    margin: 0px;
    margin-top: 10px;
}
.notes-description {
    font-size: 17px;
    font-family: regularText;
    color: #333333;
    padding-left: 20px;
    margin: 0px;
}
.details-page-chat-window {
    width: 90%;
    height: 500px;
    margin-top: 30px;
    background-color: white;
    box-shadow: -1px 3px 6px #0000001A;
    border-radius: 40px;
    padding: 15px;
}
.user-details-personal-info-card {
    width: 90%;
    height: 100px;
    box-shadow: 0px 2px 4px #0000001A;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.personal-info-section {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #877CEC;
}
.personal-info-section:last-child {
    border: none;
}
.personal-info-description {
    font-size: 16px;
    font-family: mediumText;
    color: #4939E3;
    margin: 0px;
}
.personal-info-title {
    font-size: 16px;
    font-family: mediumText;
    color: #333333;
    margin: 0px;
}
@media only screen and (max-width: 1500px) {
    .detail-main-section {
        padding-left: 7%;
    }
}
@media only screen and (max-width: 1335px) {
    .detail-main-section {
        padding-left: 0px;
        width: 100%;
        margin-top: 125px;
    }
    .notes-title {
        font-size: 15px;
    }
    .notes-description {
        font-size: 15px;
    }
    .add-tasks-button-text {
        font-size: 16px;
    }
    .detail-title {
        font-size: 25px;
    }
    .detail-every-section-personal-info {
        width: 90%;
    }
    .user-details-personal-info-card {
        width: 100%;
    }
}
@media only screen and (max-width: 1260px) {
    .detail-main-section {
        flex-direction: column;
    }
    .detail-row-grid-1 {
        width: 100%;
    }
    .detail-row-grid-2 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 50px;
    }
}
@media only screen and (max-width: 1075px) { 
    .detail-lab-work-description-section {
        flex-direction: column;
    }
}
@media only screen and (max-width: 700px) { 
    .assigned-tasks-container {
        flex-direction: column;
    }
    .assigned-tasks-cards {
        width: 90%;
    }
    .add-tasks-button {
        width: 90%;
    }
    .detail-title {
        margin-bottom: 20px;
    }
}
@media only screen and (max-width: 700px) { 
    .user-details-personal-info-card {
        flex-direction: column;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .personal-info-section {
        border: none;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}